import { useAddClientMutation, useGetClientsQuery } from 'src/redux/Api/clientsApi'
import Loader from 'src/Components/Loader'
import Popup from 'src/Components/Popup'
import { useState } from 'react'
import Input from 'src/Components/Form/Input'
import { IClient } from 'src/utils/Interfaces/client.interface'
import style from './base.module.scss'

const Dashboard = () => {
  const [newClientPopup, setNewClientPopup] = useState(false)
  const [newClient, setNewClient] = useState({} as IClient)
  const [addNewClient] = useAddClientMutation()
  const { data, isLoading } = useGetClientsQuery()

  const handleNewClient = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await addNewClient(newClient).unwrap()
    setNewClientPopup(false)
    setNewClient({} as IClient)
  }

  return (
    <>
      <div className="main_title">
        <p>Dashboard</p>
      </div>
      <section>
        <div className="block clients_list">
          <h3>Clients</h3>
          <div className="control">
            <button className="btn" onClick={() => setNewClientPopup(true)}>
              Add new client
            </button>
          </div>
          <ul>
            {isLoading ? (
              <Loader height={30} />
            ) : (
              data?.map((item) => (
                <li key={item._id}>
                  {item.name} -{' '}
                  <a href={item.site} target="_blank" rel="noreferrer">
                    {item.site}
                  </a>
                </li>
              ))
            )}
          </ul>
        </div>

        {newClientPopup && (
          <Popup closePopup={() => setNewClientPopup(false)} title="New client" className={style.new_client}>
            <form onSubmit={handleNewClient}>
              <Input
                label="Name"
                name="name"
                value={newClient.name}
                onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
              />
              <Input
                label="Site"
                name="site"
                value={newClient.site}
                onChange={(e) => setNewClient({ ...newClient, site: e.target.value })}
              />
              <button className="btn">Add</button>
            </form>
          </Popup>
        )}
      </section>
    </>
  )
}

export default Dashboard
