import { Link } from 'react-router-dom'
import { useGetBugsQuery } from 'src/redux/Api/bugTrackerApi'
import Loader from 'src/Components/Loader'
import style from './BugTracker.module.scss'
import Table from 'src/Components/Table'
import { IBugReport } from 'src/utils/Interfaces/bug.interface'

const BugTracker = () => {
  const { data: bugs, isFetching } = useGetBugsQuery('new')

  return (
    <>
      <div className="main_title">
        <p>Bug tracker</p>
      </div>
      <section className={style.bug_tracker}>
        <div className="block">
          <h3>Bugs list</h3>
          <div className="control">
            <Link to="/bug-tracker/new" className="btn">
              + add report
            </Link>
          </div>

          <Table columns={['Title', 'Experiment', 'Status', 'Dev', 'Author']}>
            {isFetching ? (
              <tr>
                <td colSpan={6} style={{ position: 'relative', padding: '40px' }}>
                  <Loader height={30} />
                </td>
              </tr>
            ) : (
              bugs?.map((item: IBugReport, idx: number) => (
                <tr key={idx}>
                  <td>
                    <Link to={'/bug-tracker/' + item._id}>{item.title}</Link>
                  </td>
                  <td>{item.experiment}</td>
                  <td>{item.status}</td>
                  <td>{item.developer?.username}</td>
                  <td>{item.author.username}</td>
                </tr>
              ))
            )}
          </Table>
        </div>
      </section>
    </>
  )
}

export default BugTracker
