import { createSlice } from '@reduxjs/toolkit'
import { IAuth } from 'src/utils/Interfaces/state.interface'

const initialState: IAuth = {
  role: null,
  name: null,
  token: null,
  id: null,
  img: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { name, token, role, id, img } = action.payload
      state.name = name
      state.token = token
      state.role = role
      state.id = id
      state.img = img
    },
    logOut: (state) => {
      state.name = null
      state.token = null
      state.role = null
    }
  },
  selectors: {
    getRole: (state) => state.role,
    getNameID: (state) => {
      return { name: state.name, id: state.id }
    },
    getToken: (state) => state.token,
    getImg: (state) => state.img
  }
})

export const { setCredentials, logOut } = authSlice.actions

export const { getNameID, getToken } = authSlice.selectors

export default authSlice.reducer
