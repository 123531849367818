import style from './style.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  accent?: boolean
}

export const TableInput = ({ value, type = 'text', onChange, onBlur, ...props }: Props) => {
  return <input className={style.input} type={type} value={value} onChange={onChange} onBlur={onBlur} {...props} />
}
