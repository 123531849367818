import { useState, ChangeEvent } from 'react'
import { TableInput } from 'src/Components/Table/TableInput'
import { TableSelect } from 'src/Components/Table/TableSelect'
import { useUpdateUserMutation } from 'src/redux/Api/userApi'
import { Role } from 'src/utils/Interfaces/user.interface'
import { IUser } from 'src/utils/Interfaces/user.interface'

const roles: { id: Role; label: string }[] = [
  { id: 'admin', label: 'Admin' },
  { id: 'user', label: 'User' },
  { id: 'guest', label: 'Guest' }
]
export const UsersControlItem = ({ item }: { item: IUser }) => {
  const [name, setName] = useState(item.name)
  const [role, setRole] = useState(item.role)
  const [startDate, setStartDate] = useState(item.startDate)
  const [phone, setPhone] = useState(item.phone)

  const [updateUser] = useUpdateUserMutation()

  const userNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const userNameBlurHandler = async () => {
    if (name === item.name) return
    await updateUser({ ...item, name })
  }

  const userstartDateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value)
  }

  const userstartDateBlurHandler = async () => {
    if (startDate === item.startDate) return
    await updateUser({ ...item, startDate })
  }

  const userRoleChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setRole(event.target.value as Role)
  }

  const userRoleBlurHandler = async () => {
    if (role === item.role) return
    await updateUser({ ...item, role })
  }

  const userPhoneChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value)
  }

  const userPhoneBlurHandler = async () => {
    if (phone === item.phone) return
    await updateUser({ ...item, phone })
  }

  return (
    <tr>
      <td>
        <TableInput value={name} onChange={userNameChangeHandler} onBlur={userNameBlurHandler} />
      </td>
      <td>{item.email}</td>
      <td>
        <TableSelect options={roles} value={role} onChange={userRoleChangeHandler} onBlur={userRoleBlurHandler} />
      </td>
      <td>
        <TableInput value={startDate} type="date" onChange={userstartDateChangeHandler} onBlur={userstartDateBlurHandler} />
      </td>
      <td>{item.emailConfirm ? 'Yes' : 'No'}</td>
      <td>
        <TableInput
          type="tel"
          value={phone}
          onChange={userPhoneChangeHandler}
          onBlur={userPhoneBlurHandler}
          placeholder="Enter user phone"
        />
      </td>
    </tr>
  )
}
