import Input from 'src/Components/Form/Input'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setParams } from 'src/redux/Slices/configSlice'

const headers = {
  Authorization: 'Bearer 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'
}

const git = 'https://api.github.com/repos/conversionratestore/projects/contents/'

const GitHub = () => {
  const [folder, setFolder] = useState('')
  // const [fileType, setFileType] = useState('img')
  const [files, setFiles] = useState<string[]>([])
  const dispatch = useDispatch()

  const getFiles = async (dir: string) => {
    const imgListFull: string[] = []
    async function checkGitFiles(url: string) {
      const imgList: string[] = []
      const answer = await (await fetch(url, { headers })).json()
      for (const item of answer) {
        if (item.type === 'dir') {
          await checkGitFiles(item.url)
        } else {
          if (
            item.name.includes('.jpg') ||
            item.name.includes('.png') ||
            item.name.includes('.jpeg') ||
            item.name.includes('.gif') ||
            item.name.includes('.svg') ||
            item.name.includes('.webp')
          ) {
            imgList.push(item.download_url)
          }
        }
      }
      imgListFull.push(...imgList)
    }
    await checkGitFiles(`${git}${dir}`)
    return imgListFull
  }

  const handleCopyUrl = (e: React.MouseEvent<HTMLImageElement>) => {
    const img = (e.target as HTMLImageElement).getAttribute('src') as string
    console.log(img)
    navigator.clipboard.writeText(img.split(folder)[1])
    dispatch(setParams({ status: 'info', message: 'Copied' }))
  }

  const handleGetFiles = async () => {
    if (!folder || folder === '') return
    const files = await getFiles(folder)
    setFiles(files)
  }

  return (
    <>
      <div className="main_title">
        <p>GitHub Files</p>
      </div>
      <section>
        <div className="block">
          <div style={{ display: 'flex', marginBottom: '20px', gap: '10px' }}>
            <Input label="Project folder" value={folder} onChange={(e) => setFolder(e.target.value)} />
            <button className="btn" onClick={handleGetFiles}>
              Get files
            </button>
          </div>
          <ul
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px'
            }}
          >
            {files.map((file, index) => (
              <li
                key={index}
                style={{ width: '100px', height: '100px', background: '#e1e1e1', padding: '10px', borderRadius: '10px', cursor: 'pointer' }}
              >
                <img src={file} alt="" style={{ height: '100%', width: '100%', objectFit: 'contain' }} onClick={handleCopyUrl} />
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  )
}

export default GitHub
