import { FormEvent } from 'react'

export function useCheckForm() {
  return function (e: FormEvent<HTMLFormElement>) {
    const form = e.currentTarget
    const inputs: NodeListOf<HTMLInputElement> = form.querySelectorAll('input, textarea')
    let check = true

    inputs.forEach((input) => {
      if (!input.value) {
        check = false
      }
    })

    return check
  }
}
