import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { IClient, IClientSurvey } from '../../utils/Interfaces/client.interface'
import { IAuth } from '../../utils/Interfaces/state.interface'

const BASE_URL = import.meta.env.VITE_BASE_URL
const GET_LIST_URL = '/client'

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as { auth: IAuth }
      const token = state.auth.token
      if (token) {
        headers.set('crs-auth-token', token)
      }
      return headers
    }
  }),
  tagTypes: ['Client'],
  endpoints: (build) => ({
    getClients: build.query<IClient[], void>({
      query: () => ({
        url: GET_LIST_URL
      }),
      providesTags: ['Client']
    }),
    getClientsSurvey: build.query<IClientSurvey[], void>({
      query: () => ({
        url: `${GET_LIST_URL}/survey`
      }),
      providesTags: ['Client']
    }),
    addClient: build.mutation<IClient, Partial<IClient>>({
      query: (body) => ({
        url: GET_LIST_URL,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Client']
    })
  })
})

export const { useGetClientsQuery, useAddClientMutation, useGetClientsSurveyQuery } = dashboardApi
