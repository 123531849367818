import { createRef, useEffect, useState } from 'react'
import style from './table.module.scss'

interface ITable {
  columns: string[]
  children?: React.ReactNode
  emptyMsg?: string
}

const Table = ({columns, children, emptyMsg = 'Data not found'} : ITable) => {
  const body = createRef<HTMLTableSectionElement>()
  const [isEmptyTbody, setIsEmptyTbody] = useState(true) 

  useEffect(() => {
    if(body.current?.children.length === 0 || body.current?.children[0].classList.contains('empty')) {
      setIsEmptyTbody(true)
    } else {
      setIsEmptyTbody(false)
    }
  }, [body])

  return (
    <div className={style.table}>
      <div className={style.table_wrapper}>
        <table>
          <thead>
            <tr>
              {columns.map((item, index) => <th key={index}>{item}</th>)}
            </tr>
          </thead>
          
          <tbody ref={body}>
            {children}
            {isEmptyTbody && <tr className='empty'><td colSpan={columns.length}>{emptyMsg}</td></tr>}
          </tbody>

        </table>
      </div>
    </div>
  )
}

export default Table
