import Sprite from 'src/assets/img/icons/icons_sprite.svg'

interface ISpriteSvgProps {
    id: string,
    color?: string
}

const SpriteSvg = ({id, color = '#ffffff'} : ISpriteSvgProps) => (
    <svg fill={color} >
        <use href={`${Sprite}#${id}`} />
    </svg>
)


export default SpriteSvg