import './header.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'src/utils/Hooks/useAuth'
import { logOut } from 'src/redux/Slices/authSlice'
import { useDispatch } from 'react-redux'
import SpriteSvg from '../SpriteSvg'
import { BASE_URL } from 'src/redux/Api/urls'

const Header = () => {
  const { token, id, img, name } = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logOutHandler = () => {
    dispatch(logOut())
    navigate('/login', { replace: true })
  }

  const getInitials = (name: string) => {
    if (name === null || name === undefined) {
      return ''
    }
    let initials = name.split(' ')[0][0];

    if (name.split(' ').length > 1) {
      initials += name.split(' ')[1][0];
    }
    return initials
  }

  return (
    <header>
      <div className="search">Search</div>
      <div className="account">
        {token ? (
          <>
            <p>{name}</p>
            <div className="avatar">
              {img !== null && img !== undefined && img !== '' ? (
                <img src={BASE_URL + '/files/users/' + id + '/avatar.jpg'} alt="avatar" />
              ) : name ? (
                <span className='initials'>{getInitials(name)}</span>
              ) : (
                <SpriteSvg id="profile" />
              )}
            </div>
            <div className="account_menu">
              <Link to="/profile">Profile</Link>
              <span className="log_out" onClick={logOutHandler}>
                Log Out
              </span>
            </div>
          </>
        ) : (
          <>
            <Link to="/login">Login</Link>
            <Link to="/sign-up">Sign Up</Link>
          </>
        )}
      </div>
    </header>
  )
}

export default Header
