import style from './list.module.scss'

interface ListProps {
    columns: string[],
    children: React.ReactNode
    className?: string
}

function List ({columns, children} : ListProps) {
    return (
        
      <ul className={style.list}>
        <li className={style.list_header}>
          {columns.map((item, index) => <span key={index}>{item}</span>)}
        </li>
        {children}
      </ul>
        
    )
}

export default List