import Table from 'src/Components/Table'
import { useGetUsersQuery } from 'src/redux/Api/userApi'
import { UsersControlItem } from './UsersControlItem'
import { IUser } from 'src/utils/Interfaces/user.interface'
const columns = ['Name', 'Email', 'Role', 'Date of start', 'Email confirm', 'Phone']

export const UsersControl = () => {
  const { data, isLoading } = useGetUsersQuery()

  return (
    <>
      <div className="main_title">
        <p>Users control</p>
      </div>
      <section>
        <Table columns={columns}>
          {isLoading ? (
            <tr>
              <td colSpan={columns.length}>Loading...</td>
            </tr>
          ) : (
            data && data.map((item: IUser, index: number) => <UsersControlItem key={index} item={item} />)
          )}
        </Table>
      </section>
    </>
  )
}
