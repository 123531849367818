import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IAuth } from 'src/utils/Interfaces/state.interface'
import { IUser } from 'src/utils/Interfaces/user.interface'

const BASE_URL = import.meta.env.VITE_BASE_URL
const USER_URL = '/user'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as { auth: IAuth }
      const token = state.auth.token
      if (token) {
        headers.set('crs-auth-token', token)
      }
      return headers
    }
  }),
  tagTypes: ['User'],
  endpoints: (build) => ({
    getUser: build.query<IUser, string>({
      query: (id) => ({
        url: `${USER_URL}/${id}`
      }),
      providesTags: ['User']
    }),
    getUsers: build.query<IUser[], void>({
      query: () => ({
        url: `${USER_URL}`
      }),
      providesTags: ['User']
    }),
    updateUser: build.mutation<IUser, Partial<IUser>>({
      query: (user) => ({
        url: `${USER_URL}`,
        method: 'PATCH',
        body: user
      }),
      invalidatesTags: ['User']
    })
  })
})

export const { useGetUserQuery, useGetUsersQuery, useUpdateUserMutation } = userApi
