import { FormEvent, useState } from 'react'
import style from './login.module.scss'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useGetLoginMutation } from 'src/redux/Api/authApi'
import { useDispatch } from 'react-redux'
import { setCredentials } from 'src/redux/Slices/authSlice'
import { useAuth } from 'src/utils/Hooks/useAuth'
import { setParams } from 'src/redux/Slices/configSlice'
import Input from 'src/Components/Form/Input'
import { useCheckForm } from 'src/utils/Hooks/useCheckForm'

const Login = () => {
  const checkForm = useCheckForm()
  const dispatch = useDispatch()
  const { token } = useAuth()
  const [pwd, setPwd] = useState('')
  const [email, setEmail] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const prev = location.state?.from?.pathname || '/dashboard'
  const [login] = useGetLoginMutation()

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const check = checkForm(e)
    try {
      if (check) {
        const result = await login({ email, pwd }).unwrap()
        dispatch(setCredentials(result))
        navigate(prev, { replace: true })
      } else {
        dispatch(setParams({ status: 'warning', message: 'Fill in required fields' }))
      }
    } catch (e) {
      dispatch(setParams({ status: 'error', message: 'Invalid email or password' }))
    }
  }

  return token ? (
    <Navigate to="/dashboard" replace={true} />
  ) : (
    <>
      <div className="main_title">
        <p>Login</p>
      </div>
      <section className={style.login}>
        <form className="block" onSubmit={handleSubmit}>
          <Input
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            type="email"
            name="email"
            label="Email"
            required
            value={email}
          />
          <Input
            onChange={(e) => {
              setPwd(e.target.value)
            }}
            type="password"
            name="pwd"
            label="Password"
            required
            value={pwd}
          />
          <button className="btn" type="submit">
            Login
          </button>
        </form>
      </section>
    </>
  )
}

export default Login
