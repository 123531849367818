const Home = () => {
  return (
    <main className="content">
      <h1>Welcome to CRS CRM system</h1>
      <p>For use our system you might complete this list:</p>
      <ol>
        <li>Registration</li>
        <li>Update profile</li>
      </ol>
    </main>
  )
}
export default Home
