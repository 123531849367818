import { useEffect, useRef, useState } from 'react'
import style from './select.module.scss'
import { motion, AnimatePresence } from 'framer-motion'

type List = { _id: string; name: string }[] | string[]

interface SelectProps<K> {
  list?: List
  children?: React.ReactNode
  onChange: (value: K) => void
  value?: K
  label: string
  light?: boolean
}

function Select<T>({ children, list, onChange, value, label, light = false, ...props }: SelectProps<T>) {
  const [val, setVal] = useState(value)

  const defaultValue: string = (() => {
    if (val) {
      if (list) {
        let result = ''
        list.forEach((item) => {
          if (typeof item === 'object' && item._id === val) {
            result = item.name
          }

          if (typeof item === 'string' && item === val) {
            result = item
          }
        })
        return result
      } else {
        return val as string
      }
    } else {
      return ''
    }
  })()
  const [showVal, setShowVal] = useState(defaultValue)
  const [showOptions, setShowOptions] = useState(false)
  const selectBlock = useRef<HTMLDivElement>(null)

  const handleClickItem = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.target instanceof HTMLLIElement) {
      setVal(e.target.dataset.value as T)
      setShowVal(e.target.innerText)
      setShowOptions(false)
    }
  }

  document.addEventListener('click', (e) => {
    if (selectBlock.current && !selectBlock.current.contains(e.target as Node)) {
      setShowOptions(false)
    }
  })

  useEffect(() => {
    onChange(val as T)
  }, [val])

  useEffect(() => {
    setVal(value)
  }, [value])

  return (
    <div className={style.select + (light ? ` ${style.light}` : '')} ref={selectBlock}>
      <span>{label}</span>
      <input
        type="text"
        data-value={val}
        readOnly
        value={showVal}
        placeholder="Select variant"
        onFocus={() => setShowOptions(true)}
        {...props}
      />
      <AnimatePresence initial={false}>
        {showOptions && (
          <motion.ul
            initial={{
              height: 0,
              opacity: 0
            }}
            animate={{
              height: 'auto',
              opacity: 1
            }}
            exit={{
              height: 0,
              opacity: 0
            }}
            transition={{
              duration: 0.3
            }}
            className={style.options}
            onClick={(e) => handleClickItem(e)}
          >
            {list &&
              list.map((item, index) => {
                if (typeof item === 'object') {
                  return (
                    <li key={index} data-value={item._id}>
                      {item.name}
                    </li>
                  )
                } else {
                  return (
                    <li key={index} data-value={item}>
                      {item}
                    </li>
                  )
                }
              })}
            {children}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Select
