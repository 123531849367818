import { useEffect, useState } from 'react'
import './footer.scss'
import Alert from '../Alert'
import { useSelector } from 'react-redux'
import { IConfig } from 'src/utils/Interfaces/state.interface'

const Footer = () => {
  const { overlayPos } = useSelector((state: { config: IConfig }) => state.config)
  const [overlay, setOverlay] = useState(false)
  useEffect(() => {
    if (overlayPos !== '') {
      setOverlay(true)
    } else {
      setTimeout(() => {
        setOverlay(false)
      }, 500)
    }
  }, [overlayPos])

  return (
    <>
      <footer>
        <p>
          <span>CRM_CRS</span>
          <span>2022</span>
        </p>
      </footer>
      <Alert />
      {overlay ? <div className="overlay" data-pos={overlayPos} /> : ''}
    </>
  )
}

export default Footer
