import { useSelector } from 'react-redux'
import { IAuth } from '../Interfaces/state.interface'

export function useAuth() {
  const { role, name, token, id, img } = useSelector((state: { auth: IAuth }) => state.auth)

  return {
    role,
    name,
    token,
    id,
    img
  }
}
