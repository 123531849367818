import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IAnswers, ISurvey } from 'src/utils/Interfaces/survey.interface'
import { IAuth, IResponse } from 'src/utils/Interfaces/state.interface'

const BASE_URL = import.meta.env.VITE_BASE_URL
const BLOCK_URL = '/surveys'

export const surveysApi = createApi({
  reducerPath: 'surveysApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as { auth: IAuth }
      const token = state.auth.token
      if (token) {
        headers.set('crs-auth-token', token)
      }
      return headers
    }
  }),
  tagTypes: ['Survey', 'Surveys'],
  endpoints: (build) => ({
    getSurveys: build.query<ISurvey[], string>({
      query: (string) => ({
        url: `${BLOCK_URL}${string ? `?${string}` : ''}`
      }),
      providesTags: ['Surveys']
    }),
    getSurvey: build.query<ISurvey, string>({
      query: (id) => ({
        url: `${BLOCK_URL}/${id}`
      }),
      providesTags: ['Survey']
    }),
    createSurvey: build.mutation<IResponse<ISurvey>, Partial<ISurvey>>({
      query: (body) => ({
        url: BLOCK_URL,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Surveys']
    }),
    updateSurvey: build.mutation<IResponse<ISurvey>, Partial<ISurvey>>({
      query: (body) => ({
        url: `${BLOCK_URL}/${body._id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Survey', 'Surveys']
    }),
    deleteSurvey: build.mutation<IResponse<unknown>, string>({
      query: (id) => ({
        url: `${BLOCK_URL}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Surveys']
    }),
    getSurveyAnswers: build.query<IAnswers, string>({
      query: (id) => ({
        url: `${BLOCK_URL}/${id}/answers`
      })
    })
  })
})

export const {
  useGetSurveysQuery,
  useLazyGetSurveysQuery,
  useGetSurveyQuery,
  useCreateSurveyMutation,
  useUpdateSurveyMutation,
  useDeleteSurveyMutation,
  useGetSurveyAnswersQuery
} = surveysApi
