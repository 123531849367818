import { lazy } from 'react'
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import Layout from './Components/Layout'
import Home from './Pages/Base/Home'
import { CheckAuth } from './utils/Hoc/CheckAuth'
import Dashboard from './Pages/Base/Dashboard'
import Surveys from './Pages/Survey/Surveys'
import { CheckAdmin } from './utils/Hoc/CheckAdmin'
import Admin from './Pages/Base/Admin'
import NotFound from './Pages/Base/NotFound'
import ContainersPage from './Pages/Optimize/Containers'
import ExperimentsPage from './Pages/Optimize/Experiments'
import { UsersControl } from './Pages/User/UsersControl'
import GitHub from './Pages/GitHub'
import BugTracker from './Pages/Bugs/BugTracker'
import Login from './Pages/Auth/Login'
import Register from './Pages/Auth/Register'

const Survey = lazy(() => import('./Pages/Survey'))
const ExperimentPage = lazy(() => import('./Pages/Optimize/Experiment'))
const BugReport = lazy(() => import('./Pages/Bugs/BugReport'))
const Profile = lazy(() => import('./Pages/User/Profile'))

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="sign-up" element={<Register />} />
      <Route element={<CheckAuth />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="bug-tracker" element={<BugTracker />} />
        <Route path="profile" element={<Profile />} />
        <Route path="bug-tracker/:id" element={<BugReport />} />
        <Route path="surveys" element={<Surveys />} />
        <Route path="surveys/new" element={<Survey />} />
        <Route path="surveys/:id" element={<Survey />} />
        <Route path="optimize" element={<ContainersPage />} />
        <Route path="optimize/:id/experiments" element={<ExperimentsPage />} />
        <Route path="optimize/:id/experiments/:exp" element={<ExperimentPage />} />
        <Route path="github" element={<GitHub />} />
      </Route>
      <Route element={<CheckAdmin />}>
        <Route path="admin-panel" element={<Admin />} />
        <Route path="users-control" element={<UsersControl />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
)

export default Router
