import loader from './loader.module.scss'

type LoaderProps = {
    height: number
}


const Loader = ({height = 30} : LoaderProps) => {
    return (
        <div className={loader.center} style={{height: height}}>
            <div className={loader.wave} />
            <div className={loader.wave} />
            <div className={loader.wave} />
            <div className={loader.wave} />
            <div className={loader.wave} />
            <div className={loader.wave} />
            <div className={loader.wave} />
            <div className={loader.wave} />
            <div className={loader.wave} />
            <div className={loader.wave} />
        </div>
    )
}

export default Loader
