import style from './style.module.scss'

interface Props {
  options: { id: string; label: string }[],
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void,
}
export const TableSelect = ({ options, value, onChange, onBlur }: Props) => {
  return (
    <select className={style.select} name="" id="" defaultValue={value} onChange={onChange} onBlur={onBlur}>
      {options.map((item) => (
        <option key={item.id} value={item.id}>
          {item.label}
        </option>
      ))}
    </select>
  )
}
