import { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import './aside.scss'
import SpriteSvg from '../SpriteSvg'
import { useDispatch } from 'react-redux'
import { setOverlay } from 'src/redux/Slices/configSlice'
import { useAuth } from 'src/utils/Hooks/useAuth'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined'
import GitHubIcon from '@mui/icons-material/GitHub'

const Aside = () => {
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  const { role } = useAuth()
  const activeAside = () => {
    if (active) {
      dispatch(setOverlay(''))
    } else {
      dispatch(setOverlay('mid'))
    }
    setActive(!active)
  }

  const menu = [
    {
      title: 'Dashboard',
      icon: DashboardOutlinedIcon,
      link: '/dashboard'
    },
    {
      title: 'Survey',
      icon: ContentPasteOutlinedIcon,
      link: '/surveys'
    },
    {
      title: 'Optimize',
      icon: AbcOutlinedIcon,
      link: '/optimize'
    },
    {
      title: 'Bug Tracker',
      icon: BugReportOutlinedIcon,
      link: '/bug-tracker'
    },
    {
      title: 'Github',
      icon: GitHubIcon,
      link: '/github'
    },
    {
      title: 'Admin',
      icon: AdminPanelSettingsOutlinedIcon,
      link: '/admin-panel'
    },
    {
      title: 'Users Control',
      icon: ManageAccountsOutlinedIcon,
      link: '/users-control'
    }
  ]

  return (
    <aside className={active ? 'active' : ''}>
      <ul>
        <li>
          <Link to="/" className="logo">
            <SpriteSvg id="logo_cart" />
            <span className="text">
              <SpriteSvg id="logo_text" />
            </span>
          </Link>
        </li>
        {menu.map((item, index) => {
          if (role === 'admin') {
            return (
              <li key={index}>
                <NavLink to={item.link}>
                  <item.icon />
                  <span>{item.title}</span>
                </NavLink>
              </li>
            )
          } else if (item.title !== 'Admin' && item.title !== 'Users Control') {
            return (
              <li key={index}>
                <NavLink to={item.link}>
                  <item.icon />
                  <span>{item.title}</span>
                </NavLink>
              </li>
            )
          }
        })}
        <li
          className="arrow"
          onClick={() => {
            activeAside()
          }}
        >
          <SpriteSvg id="arrow_aside" />
        </li>
      </ul>
    </aside>
  )
}

export default Aside
