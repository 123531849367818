import { FormEvent, useEffect, useState } from 'react'
import style from './register.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useRegisterMutation } from 'src/redux/Api/authApi'
import { useDispatch } from 'react-redux'
import { setParams } from 'src/redux/Slices/configSlice'
import Input from 'src/Components/Form/Input'
import { useCheckForm } from 'src/utils/Hooks/useCheckForm'

const Register = () => {
  const checkForm = useCheckForm()
  const dispatch = useDispatch()
  const [register] = useRegisterMutation()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [matchPwd, setMatchPwd] = useState('')
  const [validMatch, setValidMatch] = useState(false)

  useEffect(() => {
    const match = pwd === matchPwd && pwd.length !== 0
    setValidMatch(match)
  }, [pwd, matchPwd])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const check = checkForm(e)
    if (!check) {
      dispatch(setParams({ status: 'warning', message: 'Fill in required fields' }))
      return
    }
    if (!validMatch) {
      dispatch(setParams({ status: 'error', message: 'Passwords don`t match' }))
      return
    }
    if (pwd.length < 8) {
      dispatch(setParams({ status: 'warning', message: 'Password min length 8 characters' }))
      return
    }

    const response = await register({ email, pwd }).unwrap()
    dispatch(setParams(response))
    navigate('/login', { replace: true })
  }

  return (
    <section className={style.registration}>
      <div className="main_title">
        <p>Registration</p>
      </div>
      <form onSubmit={handleSubmit} className="block">
        <Input type="email" name="email" label="Email" onChange={(e) => setEmail(e.target.value)} required value={email} />
        <Input onChange={(e) => setPwd(e.target.value)} type="password" name="pwd" label="Password" required value={pwd} />
        <Input
          onChange={(e) => setMatchPwd(e.target.value)}
          type="password"
          name="matchPwd"
          label="Confirm password"
          required
          value={matchPwd}
        />
        <button type="submit" className="btn">
          Sign Up
        </button>
      </form>
      <p>
        Already registered?
        <br />
        <Link to="/login">Sign In</Link>
      </p>
    </section>
  )
}

export default Register
