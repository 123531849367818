const Admin = () => {
    return (
        <div>
            <h1>Admin</h1>
            <div className="btn">text</div>
        </div>
    )
}

export default Admin
