import style from './popup.module.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface PopupProps {
  children: React.ReactNode
  title: string
  closePopup: () => void
  className?: string
}

const Popup = ({ children, title = 'Popup', closePopup, className, ...props }: PopupProps) => {
  return (
    <div className={style.popup_wrapper + ' ' + className} {...props}>
      <div className={style.popup_block}>
        <div className={style.popup_header}>
          {title}
          <button className={style.popup_close} onClick={closePopup}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className={style.popup_content}>{children}</div>
      </div>
    </div>
  )
}

export default Popup
