import Popup from '../Popup'

interface Props {
  title: string
  closePopup: () => void
  accept: () => void
}

const FinalAccept = ({ title, closePopup, accept }: Props) => {
  return (
    <Popup title={title} closePopup={closePopup}>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <button className="btn" onClick={accept}>
          Yes
        </button>
        <button className="btn btn_clear" onClick={closePopup}>
          No
        </button>
      </div>
    </Popup>
  )
}

export default FinalAccept
