import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IAuth, IResponse } from '../../utils/Interfaces/state.interface'
import { IContainer, IExperiment } from 'src/utils/Interfaces/optimize.interface'

const BASE_URL = import.meta.env.VITE_BASE_URL
const PART_URL = '/optimize/container'

export const optimizeApi = createApi({
  reducerPath: 'optimizeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as { auth: IAuth }
      const token = state.auth.token
      if (token) {
        headers.set('crs-auth-token', token)
      }
      return headers
    }
  }),
  tagTypes: ['Container', 'Experiment'],
  endpoints: (build) => ({
    getContainers: build.query<IContainer[], string>({
      query: () => ({
        url: PART_URL
      }),
      providesTags: ['Container']
    }),
    getContainer: build.query<IContainer, string>({
      query: (id) => ({
        url: `${PART_URL}/${id}`
      })
    }),
    createContainer: build.mutation<IResponse<IContainer>, Partial<IContainer>>({
      query: (body) => ({
        url: PART_URL,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Container']
    }),
    updateContainer: build.mutation<IResponse<IContainer>, Partial<IContainer>>({
      query: (body) => ({
        url: `${PART_URL}/${body._id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Container']
    }),
    deleteContainer: build.mutation<IResponse<IContainer>, string>({
      query: (id) => ({
        url: `${PART_URL}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Container']
    }),
    getExperiments: build.query<IExperiment[], string>({
      query: (containerId) => ({
        url: '/optimize/experiment',
        params: {
          containerId
        }
      }),
      providesTags: ['Experiment']
    }),
    getExperiment: build.query<IExperiment, string>({
      query: (id) => ({
        url: `/optimize/experiment/${id}`
      }),
      providesTags: ['Experiment']
    }),
    createExperiment: build.mutation<IResponse<IExperiment>, Partial<IExperiment>>({
      query: (body) => ({
        url: '/optimize/experiment',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Experiment']
    }),
    updateExperiment: build.mutation<IResponse<IExperiment>, Partial<IExperiment>>({
      query: (body) => ({
        url: `/optimize/experiment/${body._id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Experiment']
    }),
    deleteExperiment: build.mutation<IResponse<IExperiment>, string>({
      query: (id) => ({
        url: `/optimize/experiment/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Experiment']
    })
  })
})

export const {
  useGetContainersQuery,
  useGetContainerQuery,
  useCreateContainerMutation,
  useUpdateContainerMutation,
  useDeleteContainerMutation,
  useGetExperimentsQuery,
  useGetExperimentQuery,
  useCreateExperimentMutation,
  useUpdateExperimentMutation,
  useDeleteExperimentMutation
} = optimizeApi
