import { useLazyGetSurveysQuery, useCreateSurveyMutation, useDeleteSurveyMutation } from 'src/redux/Api/surveysApi'
import style from '../surveys.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import Table from 'src/Components/Table'
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined'
import FinalAccept from 'src/Components/FinalAccept'
import { useEffect, useState } from 'react'
import { ISurvey } from 'src/utils/Interfaces/survey.interface'
import { useGetClientsSurveyQuery } from 'src/redux/Api/clientsApi'

const Surveys = () => {
  const [trigger] = useLazyGetSurveysQuery()
  const { data: clientsData } = useGetClientsSurveyQuery()
  const [createSurveyMutation] = useCreateSurveyMutation()
  const [deleteSurveyMutation] = useDeleteSurveyMutation()
  const [popupConfirm, setPopupConfirm] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const navigate = useNavigate()
  const [folder, setFolder] = useState(null as string | null)
  const [surveys, setSurveys] = useState([] as ISurvey[])

  const createSurvey = async () => {
    const survey = await createSurveyMutation({ name: 'New Survey' }).unwrap()
    navigate(`/surveys/${survey.data?._id}`)
  }

  const handleDelete = async (id: string) => {
    setDeleteId(id)
    setPopupConfirm(true)
  }

  const acceptDelete = async () => {
    await deleteSurveyMutation(deleteId)
    setPopupConfirm(false)
    setDeleteId('')
  }

  const fetchSurveys = async () => {
    if (!folder) return
    const surveys = await trigger(`client._id=${folder}`).unwrap()
    setSurveys(surveys)
  }

  useEffect(() => {
    fetchSurveys()
  }, [folder])

  return (
    <>
      <div className="main_title">
        <p>Surveys</p>
      </div>
      {!folder ? (
        <ul className={style.folders}>
          {clientsData?.map((client) => (
            <li key={client._id} onClick={() => setFolder(client._id)}>
              <span>{client.name}</span>
              <span>{client.surveysList?.length}</span>
            </li>
          ))}
        </ul>
      ) : (
        <section className={style.surveys}>
          <div className="block">
            <h3>List of surveys</h3>
            <div className={style.control}>
              <button className="btn" onClick={() => setFolder(null)}>
                Switch client
              </button>
              <button className="btn" onClick={createSurvey}>
                + Add New
              </button>
            </div>
            <Table columns={['Name', 'Client', 'Status', 'Create date', 'Total answers', 'Actions']}>
              {surveys?.map((survey) => (
                <tr key={survey._id}>
                  <td>
                    <Link to={`${survey._id}`}>{survey.name}</Link>
                  </td>
                  <td>{survey.client?.name}</td>
                  <td>
                    <span className={survey.status}></span>
                    <span>{survey.status}</span>
                  </td>
                  <td>{new Date(survey.createdAt || '').toLocaleDateString()}</td>
                  <td>{survey.answersId.length}</td>
                  <td>
                    <DeleteOutlineOutlined className={style.delete} onClick={() => handleDelete(survey._id as string)} />
                  </td>
                </tr>
              ))}
            </Table>
          </div>
          {popupConfirm && <FinalAccept title="Are you sure?" closePopup={() => setPopupConfirm(false)} accept={acceptDelete} />}
        </section>
      )}
    </>
  )
}

export default Surveys
