import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IAuth, IResponse } from '../../utils/Interfaces/state.interface'
import { IBugReport } from 'src/utils/Interfaces/bug.interface'

const BASE_URL = import.meta.env.VITE_BASE_URL
const LIST_URL = '/bug'

export const bugTrackerApi = createApi({
  reducerPath: 'bugTrackerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as { auth: IAuth }
      const token = state.auth.token
      if (token) {
        headers.set('crs-auth-token', token)
      }
      return headers
    }
  }),
  tagTypes: ['Bug'],
  endpoints: (build) => ({
    getBugs: build.query<IBugReport[], string>({
      query: (status = 'new') => ({
        url: `${LIST_URL}?status=${status}`
      }),
      providesTags: ['Bug']
    }),
    getBug: build.query<IBugReport, string>({
      query: (id) => ({
        url: `${LIST_URL}/${id}`
      }),
      providesTags: ['Bug']
    }),
    createBug: build.mutation<IResponse<IBugReport>, Partial<IBugReport>>({
      query: (body) => ({
        url: LIST_URL,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Bug']
    }),
    deleteBug: build.mutation<IResponse<IBugReport>, string>({
      query: (id) => ({
        url: `${LIST_URL}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Bug']
    }),
    updateBug: build.mutation<IResponse<IBugReport>, Partial<IBugReport>>({
      query: (body) => ({
        url: `${LIST_URL}/${body._id}`,
        method: 'PATCH',
        body
      })
      // invalidatesTags: ['Bug']
    })
  })
})

export const { useGetBugsQuery, useGetBugQuery, useCreateBugMutation, useDeleteBugMutation, useUpdateBugMutation } = bugTrackerApi
