/* eslint-disable @typescript-eslint/no-explicit-any */
import { isFulfilled, isRejected } from '@reduxjs/toolkit'
import { setParams } from '../Slices/configSlice'

export const rtkQueryResponseLogger = (api: any) => (next: any) => (action: any) => {
  if (isRejected(action)) {
    console.log('%c isRejected ', 'background: red; font-size: 18px; color: #fff;')
    console.log(action)
    if (!action.payload.data?.status || !action.payload.data || !action.payload) {
      api.dispatch(setParams({ status: 'error', message: 'No response from server' }))
    } else {
      api.dispatch(
        setParams({
          status: action.payload.data?.status || 'error',
          message: action.payload.data?.message || action.payload.error
        })
      )
    }
  }
  if (isFulfilled(action)) {
    console.log(action)
    if (action.payload.status && action.payload.message) {
      api.dispatch(
        setParams({
          status: action.payload.status,
          message: action.payload.message
        })
      )
    }
  }
  return next(action)
}
