import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import Input from 'src/Components/Form/Input'
import Select from 'src/Components/Form/Select'
import Loader from 'src/Components/Loader'
import Popup from 'src/Components/Popup'
import Table from 'src/Components/Table'
import { useCreateExperimentMutation, useGetExperimentsQuery } from 'src/redux/Api/optimize.api'
import { IExperiment } from 'src/utils/Interfaces/optimize.interface'

const ExperimentsPage = () => {
  const { id } = useParams()
  if (!id) return null
  const { data: experiments, isLoading } = useGetExperimentsQuery(id)
  const [createExperiment] = useCreateExperimentMutation()
  const [newExp, setNewExp] = useState(false)
  const [exp, setExp] = useState<IExperiment>({} as IExperiment)
  const navigate = useNavigate()

  const createExperimentHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setNewExp(false)
    const result = await createExperiment({ ...exp, containerId: id }).unwrap()
    if (result.data) navigate(`${result.data._id}`)
  }

  return (
    <>
      <div className="main_title">
        <p>Experiments</p>
      </div>
      <section>
        <div className="block">
          <h3>List of experiments</h3>
          <div className="control">
            <button className="btn" onClick={() => setNewExp(true)}>
              Create
            </button>
          </div>
          <Table columns={['Name', 'Type', 'Created', 'Last modified', 'Status', '']}>
            {isLoading ? (
              <tr>
                <td>
                  <Loader height={30} />
                </td>
              </tr>
            ) : (
              experiments?.map((item: IExperiment) => (
                <tr key={item._id}>
                  <td>
                    <Link to={`${item._id}`}>{item.name}</Link>
                  </td>
                  <td>{item.type}</td>
                  <td>{item.createdAt}</td>
                  <td>{item.updatedAt}</td>
                  <td>{item.status}</td>
                  <td>
                    <FontAwesomeIcon icon={faCopy} />
                    <FontAwesomeIcon icon={faTrash} />
                  </td>
                </tr>
              ))
            )}
          </Table>
        </div>
        {newExp && (
          <Popup title="New experience" closePopup={() => setNewExp(false)}>
            <form onSubmit={createExperimentHandler}>
              <Input label="Name" name="name" value={exp.name} onChange={(e) => setExp({ ...exp, name: e.target.value })} />
              <Input
                label="Description"
                name="description"
                value={exp.description}
                onChange={(e) => setExp({ ...exp, description: e.target.value })}
              />
              <Select label="Type" value={exp.type} onChange={(e) => setExp({ ...exp, type: e })}>
                <li data-value="A/B">A/B</li>
                <li data-value="Redirect">Redirect</li>
              </Select>
              <button className="btn">Create</button>
            </form>
          </Popup>
        )}
      </section>
    </>
  )
}

export default ExperimentsPage
