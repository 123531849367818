import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import Aside from './Aside'
import { Suspense } from 'react'

const Layout = () => {
  return (
    <div className="main_wrapper">
      <Aside />
      <div className="main_content">
        <Header />
        <main>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
          <Footer />
        </main>
      </div>
    </div>
  )
}

export default Layout
