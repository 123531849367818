import { IContainer } from 'src/utils/Interfaces/optimize.interface'
import { useCreateContainerMutation, useGetContainersQuery } from 'src/redux/Api/optimize.api'
import Loader from 'src/Components/Loader'
import Popup from 'src/Components/Popup'
import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import style from './optimize.module.scss'
import Input from 'src/Components/Form/Input'
import Select from 'src/Components/Form/Select'
import { useGetClientsQuery } from 'src/redux/Api/clientsApi'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import List from 'src/Components/List'

const ContainersPage = () => {
  const { data: containers, isLoading } = useGetContainersQuery('')
  const { data: clients } = useGetClientsQuery()
  const [createContainer] = useCreateContainerMutation()
  const [addNew, setAddNew] = useState(false)
  const [newContainer, setNewContainer] = useState<IContainer>({} as IContainer)

  const addNewHandler = () => {
    setAddNew(!addNew)
  }

  const newContainerHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await createContainer(newContainer).unwrap()
    setAddNew(false)
    setNewContainer({} as IContainer)
  }

  const moreActions = (target: EventTarget) => {
    console.log(target)
  }

  return (
    <>
      <div className="main_title">
        <p>Optimizer</p>
      </div>
      <section className={style.optimize}>
        <div className="block">
          <h3>Containers</h3>
          <div className="control">
            <button className="btn" onClick={addNewHandler}>
              + Add New
            </button>
          </div>
        </div>
        <List columns={['Name', 'Client', 'Created At', 'Updated At', '*']}>
          {isLoading ? (
            <li>
              <Loader height={30} />
            </li>
          ) : (
            containers?.map((item: IContainer) => (
              <li key={item._id}>
                <span>
                  <Link to={'/optimize/' + item._id + '/experiments'}>{item.name}</Link>
                </span>
                <span>{item.client}</span>
                <span>{item.createdAt}</span>
                <span>{item.updatedAt}</span>
                <span>
                  <FontAwesomeIcon icon={faEllipsis} onClick={(e) => moreActions(e.target)} />
                </span>
              </li>
            ))
          )}
        </List>
        {addNew && (
          <Popup title="Add New" closePopup={addNewHandler} className={style.add_container}>
            <form onSubmit={newContainerHandler}>
              <Input
                label="Name"
                name="name"
                value={newContainer.name}
                onChange={(e) => setNewContainer({ ...newContainer, name: e.target.value })}
              />
              <Select<string> label="Client" onChange={(e) => setNewContainer({ ...newContainer, client: e })}>
                {clients?.map((item) => (
                  <li key={item._id} data-value={item.name}>
                    {item.name}
                  </li>
                ))}
              </Select>
              <button className="btn">Submit</button>
            </form>
          </Popup>
        )}
      </section>
    </>
  )
}

export default ContainersPage
