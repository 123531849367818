import style from './input.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  type?: string
  name?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  light?: boolean
}

const Input = ({ label = 'Text', type = 'text', name = 'input', value, onChange, light = false, ...props }: Props) => {
  return (
    <div className={style.input + (light ? ` ${style.light}` : '')}>
      <input type={type} name={name} id={name} value={value || ''} onChange={onChange} {...props} placeholder="p" />
      <label htmlFor={name}>{label}</label>
    </div>
  )
}

export default Input
